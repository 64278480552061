:root{
    --primary-grey: #ccd1d9;
    --primary-dark-grey: #666666;
    --primary-dark: rgba(255,255,255,0)
}

.experience{
    padding: 0.7rem;
}

.experience-wrapper{
    position: relative;
    padding: 3.125rem 0;
    overflow: hidden;
}

.experience h1{
    text-transform: uppercase;
    color: var(--primary-orange);
    padding-bottom: 3.125rem;
}

.experience-wrapper::before{
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    margin-left: -0.0625rem;
    width: 0.125rem;
    height: 100%;
    background: var(--primary-grey);
    z-index: 1;
}

.timeline-block{
    width: calc(50% + 0.5rem);
    display: flex;
    justify-content: space-between;
    clear: both;
}

.timeline-block-right{
    float: right;
}

.timeline-block-left{
    float: left;
    direction: rtl;
}

.marker{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;;
    border: 0.3125rem solid var(--primary-dark-grey);
    background: var(--primary-orange);
    margin-top: 0.625rem;
    z-index: 3;
}

.timeline-content{
    width: 95%;
    padding: 0 0.9375rem;
    color: var(--primary-dark-grey);
    direction: ltr;
}

.timeline-content h3{
    margin-top: 00.3125rem;
    color: var(--primary-red);
    font-size: 900;
}

.timeline-content h4{
    margin-top: 00.3125rem;
    color: var(--primary-orange);
    font-size: 900;
}

@media(max-width: 786px){
    .experience .container{
        padding-left: 0;
    }

    .experience-wrapper::before{
        left: 0.5rem;
        margin-bottom: 1.875rem;
    }

    .timeline-block-right{
        float: none;
        width: 100%;
    }

    .timeline-block-left{
        float: none;
        direction: ltr;
        width: 100%;
    }

}
