.footer{
    background: black;
    color: var(--primary-dark-grey);
    padding: 3rem;
}

.footer a{
    text-decoration: none;
    color: var(--primary-dark-grey);
}

.footer a:hover{
    text-decoration: none;
    color: var(--primary-orange);
    cursor: pointer;
}

.footer p{
    margin-bottom: 0;
}