:root{
    --primary-white: #fff;
    --primary-orange: #f9ab00;
    --primary-dark: rgba(255,255,255,0);
}

.logo{
    width: 7.25rem;
}

nav{
    z-index: 2;
}
nav a.nav-link{
    letter-spacing: 0.0375rem;
    cursor: pointer;
}

nav ul li{
    text-transform: uppercase;
}

.navbar-nav > li> a{
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    color: var(--primary-white) !important;
    border-top: 0.1875rem solid var(--primary-dark) ;
}

.navbar-brand{
    max-width: 17.25rem;
    padding: 0 !important;
    height: 6rem !important;
}

@media(max-width: 768px){
    .navbar-brand{
    width: 7rem;
  }
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus{
    border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav> li> a:hover{
    border-top: 0.1875rem solid var(--primary-orange);
}
