:root{
    --primary-black: #000000;
}

.portfolio-wrapper{
    background: var(--primary-light-grey);
    padding: 3rem 0;
}

.portfolio-wrapper h1{
    color: var(--primary-orange);
}

.portfolio-image{
    width: 15rem;
    height: 8rem;
    border: 1px solid var(--primary-orange);
    padding: 0 0.5rem;
    
}

.weather-image{
    width: 15rem;
    height: 8rem;
    border: 1px solid var(--primary-orange);
    padding: 0 0.5rem;
}

.portfolio-image-box{
    position: relative;
    margin: 1rem;
}

.overflow{
    position: absolute;
    opacity: 0;
    background: var(--primary-black);
    width: 15rem;
    height: 8rem;
    top: 0;
    border: 1px solid var(--primary-red);
    cursor: pointer;
}

.portfolio-image-box:hover .overflow{
    opacity: 60%;
    transition: 0.3s ease-in-out;
}

.portfolio-icon{
    position: absolute;
    opacity: 0;
    margin-left: -8rem;
    margin-top: 3.6rem;
    color: var(--primary-orange);
    font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon{
    opacity: 70%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.popupbox-wrapper {
    margin-top: 7rem;
    width: 60rem;

  }

.portfolio-image-popupbox{
    width: 38rem;
    padding: 0 0.5rem;
}

.weather-image-popupbox{
    width: 20rem;
    height: 20rem;
    padding: 0 0.5rem;
}

.hyper-link{
cursor: pointer;
color: var(--primary-orange);
}

.hyper-link:hover{
    color: var(--primary-red);
    }


@media (max-width: 768px){
    .portfolio-image-popupbox{
        width: 100%;
    }
}

@media (max-height: 570px){
    .popupbox-wrapper {
        height: 100%;
      }

    .portfolio-image-popupbox{
        width: 50%;
    }
}

@media (max-height: 280px){
    .popupbox-wrapper {
        height: 100%;
      }

    .portfolio-image-popupbox{
        width: 30%;
    }
}