:root{
    --primary-light-grey: #f4f4f4;
}

.aboutme{
    background: var(--primary-light-grey);
}
.photo-wrap{
    display:flex;
    justify-content: center;
    align-items: center;
}

.profile-img{
    width: 16rem;
    border-radius: 50%;
    padding: .3rem;
    border: 1px solid var(--primary-orange);
}
@media(max-width: 468px){
    .profile-img{
        width: 10rem;
    }
}

.about-heading{
    color: var(--primary-orange);
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif
}