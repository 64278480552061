:root{
    --primary-red: #ef4035;
    --primary-hover-red: #f33501;
    
}

.header-wraper{
    position: relative;
    background: url(wall-and-laptop-background.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.main-info{
    display: flex;
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
}

.main-info h1 {
    color: var(--primary-orange);
    text-transform: uppercase;
}

@media(max-width: 768px){
    .main-info h1{
        font-size: 1.7rem !important;
    }
    .typed-text{
        font-size: 1.3rem !important;
    }
  }


.typed-text{
    font-size: 2rem;
    color: var(--primary-white);

}

.btn-main-offer{
    border: 1px solid var(--primary-white);
    text-transform: uppercase;
    border-radius: 0;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
    color: var(--primary-white);
    margin: 2rem;
}

.btn-main-offer:hover{
    text-decoration: none;
    background-color: var(--primary-orange);
    color: white;
    transition: .2s ease-in-out;
}

