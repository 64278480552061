:root{
    --primary-dark: rgba(255,255,255,0)
}

.contacts{
    background: var(--primary-dark-grey);
    padding: 3rem 0;
}

.contacts h1{
    color: var(--primary-orange);
    padding: 1.3rem 0;
    text-transform: uppercase;
}

.contacts p{
    color: var(--primary-white);
    font-size: 1.2rem;
 
}

.contacts input, .contacts textarea{
    width: 100%;
    box-sizing: border-box;
    background: none;
    resize: none;
    border: 0;
    color: var(--primary-orange);
    border-bottom: 0.125rem solid var(--primary-grey) ;
    border-radius: 0;
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 1rem;
    padding-left: 0 !important;
}

.contacts input::placeholder, .contacts textarea::placeholder{
    color: white;
}

.contacts .container{
    padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus{
    background: transparent;
    color: var(--primary-orange);
    border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea{
    height: 12.2rem !important;
}

.contact-btn{
    margin: 1rem 0 0 0;
    width: 100%;
    background: none;
    border-color: var(--primary-orange);
}

.line{
    position: relative;
    top: -0.8rem;
    width: 0;
    height: 0.125rem;
    background: var(--primary-orange);
    display: inline-block;

}

input:focus+.line, textarea:focus+.line{
    width: 100%;
    transition: width 0.3s ease-in-out;
}

input.form-control{
    margin: -0.3rem 0;
}

textarea.form-control{
    margin: -0.3rem 0;
}

.error-message{
    color: var(--primary-white);
    background-color: var(--primary-red);
    text-align: left;
}
.success-message{
    color: green;
    font-weight: 900;
    background: var(--primary-white);
}

@media(max-width: 768px){
    .contacts{
        overflow-x: hidden;
    }
    .contacts .container{
        padding: 0 1rem;
    }

    .contacts p{
        padding: 0 0.4rem;
    }
}